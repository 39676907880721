import React from "react"
import { Link } from "gatsby"
import { RiArrowDropRightLine } from "react-icons/Ri"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <ul className="page_list">
      <li>
        <Link className="page_link" to="/">
          Home
        </Link>
      </li>
      <li>
        <RiArrowDropRightLine className="page_arrow" />
      </li>
      <li>404:Not Found</li>
    </ul>
    <div className="not_txtbox">
      <h1 className="not_title">404</h1>
      <h2 className="not_titles">Not Found</h2>
      <p className="not_txt">
        申し訳ございません。お探しのページは見つかりませんでした。
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
